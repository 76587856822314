// stylelint-disable scss/at-if-closing-brace-newline-after, scss/at-if-closing-brace-space-after

$include-rtl: true !default;

// rtl()
//
// Includes Right-To-Left language support by adding a selector of
// `[dir="rtl"]`.
//
// Can be turned off globally by setting `$include-rtl: false;`.
//
// $selector = ':dir(rtl)' - The RTL selector.
@mixin rtl($selector: ':dir(rtl)') {
  @if $include-rtl {
    @if & {
      &#{$selector} {
        @content;
      }
    }
    @else {
      #{$selector} {
        @content;
      }
    }
  }
}
