// Divider
//
// Can be used as an `<hr>`, an empty `<div>` or as a container.

.divider,
%divider {
  // Add vertical rhythm margins.
  @include margin-block(1);
  border: 0;
  border-top: 1px solid var(--color-border);

  // If used as a container, add a top margin to the first child.
  > :first-child {
    @include margin-top(1);
  }
}
