// stylelint-disable property-no-vendor-prefix

// Form defaults.

form {
  // Add vertical rhythm margins.
  @include margin-block(0 1);
}

input,
textarea,
select {
  @include margin-block(0 1);
}

label {
  display: block;
  font-weight: var(--fw-bold);

  // Mark field as required if needed.
  &:has(+ :required) {
    &::after {
      content: '*';
      color: var(--color-mark-highlight);
    }
  }
}

// Set max text line width for readability.
input,
label,
textarea,
select {
  max-width: var(--max-line-width);
}

fieldset {
  border: 1px solid var(--color-border);
}

input {
  // Writing "Min" instead of "min" to force use of css "min()" instead of sass "min()".
  // stylelint-disable-next-line function-name-case
  width: Min(20em, 100%);
}

[type='text'],
textarea {
  // Writing "Min" instead of "min" to force use of css "min()" instead of sass "min()".
  // stylelint-disable-next-line function-name-case
  width: Min(30em, 100%);
}


// Make sure textareas without a rows attribute are not tiny.
textarea:not([rows]) {
  min-height: 10em
}

// For forms with multibple fields show submit button below the form.
input:not(:only-of-type),
textarea {
  & + [type='submit'] {
    display: block;
  }
}

// For forms none mobile with single field add space between it and the submit button.
@include respond-to(s) {
  input:only-of-type {
    &:has(+ [type='submit']) {
      margin-right: .5rem;
    }
  }
}
