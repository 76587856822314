// Zen custom styles

// Page

body {
  background-color: var(--color-backdrop);
}

.page {
  background-color: var(--color-body-bg);
}

.footer {
  border-top: 2px solid var(--color-border);
}

// Apply smaller text to blocks, footer etc.

th,
label,
legend,
figcaption {
  font-size: var(--fs-s);
}

.language-selector,
.footer {
  font-size: var(--fs-xs);
}

// Apply heading font stack and colour to labels etc.

th,
label,
legend,
.main-menu,
.header,
.footer {
  color: var(--color-headings);
  font-family: var(--ff-headings);
}

th,
label {
  font-weight: var(--fw-headings);
}

// Article and content.

article,
aside {
  & > ul {
    @extend %ul-straight-left;
  }

  & > ol {
    @extend %ol-straight-left;
  }
}

article {
  .submitted,
  .tags {
    @include respond-to(s) {
      display: inline-block;
      padding-inline-end: 1rem;
    }
  }

  code {
    font-size: var(--fs-xs);
    overflow-wrap: break-word;
  }

  pre {
    border-radius: var(--radius-xs);
    padding: 5px;
    border: 1px solid var(--color-border-light);
    background-color: var(--color-text-bg);
    overflow: auto;
    max-height: 300px;
    max-width: 90vw;

    &.chroma {
      max-height: initial;
      overflow-x: scroll;
    }

    & > code {
      display: inline-block;
      white-space: pre;
    }
  }
}

.content-dates {
  text-align: end;
}

// Navigation

.main-menu {
  margin-top: .75rem;
  outline: 0;

  li {
    margin-bottom: .75rem;
    padding: 0;
  }

  a {
    display: block;
    background-color: var(--color-button);
    color: var(--color-text-bg);
    padding: 2px 8px;
    text-decoration: none;

    &:hover,
    &:focus {
      background-color: var(--color-button-hover);
    }

    &:active,
    &[aria-current] {
      background-color: var(--color-button);
      text-decoration: underline;
    }
  }
}

// Search

.search-text {
  font-size: var(--fs-l);
}

// Form

main {
  input,
  textarea {
    &:not(:placeholder-shown):valid {
      background-color: var(--color-status-bg);
    }

    &:not(:placeholder-shown):invalid {
      background-color: var(--color-error-bg);
    }

    &:focus:invalid {
      background-color: var(--color-warning-bg);
    }
  }

  button {
    @extend %button--shadow;
  }
}

.js-submitted {
  input,
  textarea {
    &:valid {
      background-color: var(--color-status-bg);
    }

    &:invalid {
      background-color: var(--color-error-bg);
    }
  }
}
