// stylelint-disable property-no-vendor-prefix
// Reset browsers to some sane defaults.

// Document

// Use the saner border-box model for all elements.
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  // Prevent adjustments of font size.
  -webkit-text-size-adjust: none;
  text-size-adjust: none;
  // Improve text rendering.
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  // Activate transition to intrinsic sizes like auto and fit-content.
  interpolate-size: allow-keywords;
}

// Sections

body {
  // Remove the margin in all browsers.
  margin: 0;
  padding: 0;
  // On short pages, we want the body to fill the entire height
  // of the browser.
  min-height: 100vh;
}

// Embedded content

img,
svg,
picture,
audio,
video,
canvas,
iframe,
embed,
object {
  display: block;
}

img,
svg,
video {
  max-width: 100%;
  height: auto;
}

figure {
  margin-inline: 0;
}

// Form defaults.

input,
button,
textarea,
select {
  font: inherit;
  letter-spacing: inherit;
  word-spacing: inherit;
}

[type='search'] {
  @include respond-to(s) {
    // Correct the odd appearance in Safari on desktop.
    -webkit-appearance: textfield;
  }
}

// Code

code,
kbd,
samp {
  font-family: var(--ff-monospace);
}

// Numbers

td,
math,
time[datetime*=":"] {
  font-variant-numeric: tabular-nums lining-nums;
}
