.grid-center {
  display: grid;
  place-items: center;
}

.text-center {
  text-align: center;
}

.margin-center {
  margin-inline: auto;
}
