.flex-group,
%flex-group {
  $gap: 1;

  @include gap($gap);
  display: flex;
  flex-wrap: wrap;

  & > * {
    flex: 1;
    flex-basis: 100%;
    flex-grow: 0;
  }

  &--grow {
    flex-grow: 1;
  }

  @include respond-to(s) {
    &--2 > * {
      @include flex(2, $gap);
    }

    &--3 > * {
      @include flex(3, $gap);
    }

    &--4 > * {
      @include flex(4, $gap);
    }

    &--5 > * {
      @include flex(5, $gap);
    }

    &--6 > * {
      @include flex(6, $gap);
    }
  }
}
