// stylelint-disable color-no-hex, color-named, value-list-max-empty-lines
// stylelint-disable scss/dollar-variable-colon-space-after, scss/dollar-variable-empty-line-before

// Base colors.
$black:                 #000;
$nearblack:             lighten($black, 20%); // #333
$grey-dark:             lighten($black, 40%); // #666
$grey:                  lighten($black, 60%); // #999
$grey-light:            lighten($black, 80%); // #ccc
$grey-extra-light:      lighten($black, 93.33%); // #eee
$nearwhite:             lighten($black, 97%); // #f7f7f7
$white:                 #fff;

$blue:                  #0072b9;
$red:                   #c00;
$green:                 #43a808;
$yellow:                #fd0;

$brand:                 $nearblack;
$brand-alt:             $grey;
$brand-light:           $grey-light;
$brand-dark:            $grey-dark;

// Colour map.
$colors: (
  brand:                  $brand,
  brand-alt:              $brand-alt,
  brand-light:            $brand-light,
  brand-dark:             $brand-dark,

  text:                   $black,
  text-bg:                $white,
  text-meta:              $grey-dark,

  link:                   $brand,
  link-visited:           $brand,
  link-hover:             $brand-alt,
  link-active:            $red,

  link-inverted:          $white,
  link-visited-inverted:  $white,
  link-hover-inverted:    $white,
  link-active-inverted:   $red,

  border:                 $brand,
  border-light:           $brand-light,
  border-dark:            $brand-dark,

  autocomplete:           $black,
  autocomplete-bg:        $white,
  autocomplete-select:    $white,
  autocomplete-select-bg: $blue,

  body-bg:                $white,
  header-bg:              $white,
  footer-bg:              $white,
  backdrop:               $grey-extra-light,

  mobile-menu:            $brand,
  mobile-menu-cover:      rgba($black, .2),

  button:                 $brand,
  button-hover:           $brand-alt,
  button-text:            $white,
  button-text-hover:      $white,
  button-disabled:        $grey,

  mark-highlight:         $red,
  mark-bg:                $yellow,

  menu-active:            $black,

  preview-bg:             lighten($yellow, 43%),

  row-header:             transparent,
  row-odd:                $nearwhite,
  row-even:               transparent,

  status:                 $green,
  status-bg:              lighten($green, 62%),
  warning:                $black,
  warning-bg:             lighten($yellow, 45%),
  warning-border:         $yellow,
  error:                  $red,
  error-bg:               lighten($red, 57%),

  watermark:              $grey-extra-light,

  headings:               $nearblack,

  code:                   $nearblack,

  highlight:              $brand-alt,
);
