@each $name, $space in $spacing {
  .mt--#{$name} {
    @include margin-block-start($space);
  }

  .mb--#{$name} {
    @include margin-block-end($space);
  }

  .mtb--#{$name} {
    @include margin-block($space);
  }
}
