// Figure and image.

figure,
img {
  &.image {
    --image-padding: .75rem;

    @include respond-to(s) {
      &.center {
        margin-inline: auto;
      }

      &.left {
        padding-inline-end: var(--image-padding);
        padding-block-end: var(--image-padding);
        margin: 0;
        float: left;
        clear: both;
      }

      &.right {
        padding-inline-start: var(--image-padding);
        padding-block-end: var(--image-padding);
        margin: 0;
        float: right;
        clear: both;
      }
    }
  }
}

figure {
  // Add vertical rhythm margins.
  @include margin-block(0 1);

  &.image {
    figcaption {
      padding-block-start: var(--image-padding);
      // Set max text line width in main for readability.
      max-width: var(--max-line-width);
      // Nicer text wrap, e.g. no lone word on last line.
      text-wrap: pretty;

      @include respond-to(s) {
        display: table-caption;
        caption-side: bottom;
        padding-block-start: 0;
      }

      p {
        margin: 0;
      }
    }

    @include respond-to(s) {
      display: table;

      &.left {
        figcaption {
          padding-inline-end: var(--image-padding);
          padding-block-end: var(--image-padding);
        }
      }

      &.right {
        figcaption {
          padding-inline-start: var(--image-padding);
          padding-block-end: var(--image-padding);
        }
      }
    }
  }

  &.podcast {
    audio {
      width: 95%;
    }
  }
}

img {
  &.image {
    // Add vertical rhythm margins.
    @include margin-block(0 1);
  }
}
