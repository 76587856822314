.disabled,
%disabled {
  // Re-set default cursor for disabled elements.
  cursor: default;
  color: var(--color-button-disabled);

  &:hover,
  &:focus,
  &:active {
    // Override any link underlines and color changes.
    color: var(--color-button-disabled);
    text-decoration: none;
  }
}
