@mixin spacing($property, $spacing, $unit) {
  $converted-list: ();

  @each $x in $spacing {
    $xunit: $unit;

    @if $x == 0 {
      $xunit: '';
    }

    $spacing: #{$x * $base-line-height + $xunit};
    $converted-list: join($converted-list, $spacing, $separator: space);
  }

  #{$property}: $converted-list;
}

@mixin gap($x, $unit: 'rem') {
  @include spacing(gap, $x, $unit);
}

@mixin margin($x, $unit: 'rem') {
  @include spacing(margin, $x, $unit);
}

@mixin margin-inline($x, $unit: 'rem') {
  @include spacing(margin-inline, $x, $unit);
}

@mixin margin-inline-start($x, $unit: 'rem') {
  @include spacing(margin-inline-start, $x, $unit);
}

@mixin margin-inline-end($x, $unit: 'rem') {
  @include spacing(margin-inline-end, $x, $unit);
}

@mixin margin-block($x, $unit: 'rem') {
  @include spacing(margin-block, $x, $unit);
}

@mixin margin-block-start($x, $unit: 'rem') {
  @include spacing(margin-block-start, $x, $unit);
}

@mixin margin-block-end($x, $unit: 'rem') {
  @include spacing(margin-block-end, $x, $unit);
}

@mixin margin-top($x, $unit: 'rem') {
  @include spacing(margin-top, $x, $unit);
}

@mixin margin-bottom($x, $unit: 'rem') {
  @include spacing(margin-bottom, $x, $unit);
}

@mixin margin-left($x, $unit: 'rem') {
  @include spacing(margin-left, $x, $unit);
}

@mixin margin-right($x, $unit: 'rem') {
  @include spacing(margin-right, $x, $unit);
}

@mixin padding($x, $unit: 'rem') {
  @include spacing(padding, $x, $unit);
}

@mixin padding-inline($x, $unit: 'rem') {
  @include spacing(padding-inline, $x, $unit);
}

@mixin padding-inline-start($x, $unit: 'rem') {
  @include spacing(padding-inline-start, $x, $unit);
}

@mixin padding-inline-end($x, $unit: 'rem') {
  @include spacing(padding-inline-end, $x, $unit);
}

@mixin padding-block($x, $unit: 'rem') {
  @include spacing(padding-block, $x, $unit);
}

@mixin padding-block-start($x, $unit: 'rem') {
  @include spacing(padding-block-start, $x, $unit);
}

@mixin padding-block-end($x, $unit: 'rem') {
  @include spacing(padding-block-end, $x, $unit);
}

@mixin padding-top($x, $unit: 'rem') {
  @include spacing(padding-top, $x, $unit);
}

@mixin padding-bottom($x, $unit: 'rem') {
  @include spacing(padding-bottom, $x, $unit);
}

@mixin padding-left($x, $unit: 'rem') {
  @include spacing(padding-left, $x, $unit);
}

@mixin padding-right($x, $unit: 'rem') {
  @include spacing(padding-right, $x, $unit);
}
