// Branding footer

.footer,
%footer {
  padding-block: var(--gutters);
  background-color: var(--color-footer-bg);
  text-align: center;

  p {
    margin: 0;
  }
}
