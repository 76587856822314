.flex-inline,
%flex-inline {
  --gap: .5rem;

  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
  padding: 0;
  text-align: start;

  &__item,
  li {
    list-style: none;
  }

  &--inline {
    display: inline-flex;
  }
}
