// Output a horizontal grid to help with debugging typography.
@mixin debug-grid() {
  @if $debug == true {
    $grid-height: #{$base-line-height + 'rem'};

    position: relative;
    // stylelint-disable-next-line  max-line-length
    background-image: repeating-linear-gradient(180deg, $debug-color, $debug-color 1px, transparent 1px, transparent $grid-height);

    &::after {
      content: '';
      position: absolute;
      bottom: -1px;
      left: 0;
      height: 1px;
      width: 100%;
      background-color: $debug-color;
    }
  }
}
