@import url('https://fonts.googleapis.com/css2?family=Crimson+Text:ital,wght@0,400;0,600;0,700;1,400;1,600;1,700&family=Vollkorn:ital,wght@0,400..900;1,400..900&display=swap');

body {
  font-family: "Crimson Text", serif;
  font-optical-sizing: auto;
}

h1, h2, h3 {
    font-family: 'Vollkorn', serif;
}

code {
  font-family: monospace;
}
